
import ContextMenuCloseOnClickVue from '@/views/extensions/context-menu/ContextMenuCloseOnClick.vue';

import includes from 'lodash/includes';

import { IconsPlugin } from 'bootstrap-vue';

<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Trash`) }}</h1>
                            <b-button
                                variant="danger"
                                class="float-right"
                                v-if="iCan('trash', 'write')"
                                @click="OnEmptyTrash"
                            >
                              {{ i18nT(`Empty trash`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Type`)"
                                label-for="documentType"
                            >
                                <v-select
                                    id="itemType"
                                    :clearable="false"
                                    v-model="itemType"
                                    :options="itemTypes"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div
                        class="row mx-2"
                        v-if="displayExtentControls"
                    >
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('documents', 'write')"
                            >
                                <b-dropdown-item @click="onBulkRestore">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Restore`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refItemsListTable"
                        :items="fetchItems"
                        responsive
                        :fields="tableColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data" v-if="iCan('trash', 'write')">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #cell(checkbox)="data" v-if="iCan('trash', 'write')">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div class="media">
                               <div
                                   class="media-aside align-self-center"
                               >
                                   <feather-icon
                                       :icon="itemIcon(data.item)"
                                       size="22"
                                       class="align-middle text-body"
                                   />
                               </div>
                                <div class="media-body">
                                    <p
                                        class="font-weight-bold d-block text-nowrap my-0"
                                    >{{ data.item.Label }}
                                    </p>
                                </div>
                            </div>
                        </template>

                        <template #cell(type)="data">
                                {{ i18nT(data.item.DocumentModel) }}
                        </template>

                        <template #cell(deleted_by)="data">
                                {{ data.item.UserName }}
                        </template>

                        <template #cell(deleted_on)="data">
                                {{ data.item.CreatedAt | date }}
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                                v-if="iCan('documents', 'write')"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item @click="onRestore(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Restore`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>

                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="3"
                                    v-for="document in documents"
                                    :key="document.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07);">
                                        <b-card-body class="p-0">
                                            <p> {{ getTypeName(document.Type) }}</p>
                                            <h3 style="height: 50px;">{{ document.Label }}</h3>
                                            <p
                                                style="height: 45px; overflow: hidden;"
                                                v-html="document.Description"
                                            ></p>
                                            <b-list-group
                                                class="list-group-messages"
                                                style="height: 200px"
                                            >
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.CreatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CalendarIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Created {{ document.CreatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.UpdatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CheckCircleIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Last update {{ document.UpdatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentDueDate"
                                                >
                                                    <feather-icon
                                                        icon="CastIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">Due {{
                                                            document.DocumentDueDate
                                                        }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentGroups"
                                                >
                                                    <feather-icon
                                                        icon="ListIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">
                            <span v-html="document.DocumentGroups"/>
                          </span>
                                                </b-list-group-item>
                                                <div v-if="document.AssignedTo">
                                                    <p class="mt-2">
                                                        {{ i18nT(`Assigned to`) }}
                                                    </p>
                                                    <b-list-group-item class="p-0 border-0">
                                                        <b-avatar
                                                            class="mr-1"
                                                            size="32"
                                                            :text="getAvatarName(getAssigneeName(document.AssignedTo))"
                                                            variant="light-success"
                                                        >
                                                        </b-avatar>
                                                        <span class="align-text-bottom line-height-1">{{
                                                                getAssigneeName(document.AssignedTo)
                                                            }}</span>
                                                    </b-list-group-item>
                                                </div>
                                            </b-list-group>
                                            <div>
                                            </div>

                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 mt-2 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                v-if="iCan('documents', 'write')"
                                            >

                                                <template #button-content>
                                                    <b-button>
                                                        {{i18nT(`Actions`)}}
                                                    </b-button>
                                                </template>
                                                <b-dropdown-item @click="onRestore({item: document})">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Restore`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({item: document})">
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <!-- <b-col> -->
                        <!-- </b-col> -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalItems"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BAvatar,
    BAvatarGroup,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTrashList from './useTrashList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'

import {useUtils as useI18Utils} from "@core/libs/i18n";
const {i18n} = useI18Utils();

export default {
    components: {
        BRow,
        //BFormSelect,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BAvatar,
        BAvatarGroup,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        const displayExtentControls =
            useAuth.getCurrentSoftware() != 'ca' &&
            useAuth.getCurrentSoftware() != 'em'
        return {
            itemTypes: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            icons: [
              {Label: this.i18nT(`Invoice`),DocumentModel: 'Invoice', icon: "FilePlusIcon"},
              {Label: this.i18nT(`Documents`),DocumentModel: 'Doc', icon: 'FileTextIcon'},
              {Label: this.i18nT(`Suggestion`),DocumentModel: 'Suggestion', icon: 'InboxIcon'},
              {Label: this.i18nT(`Time off`),DocumentModel: 'Leave', icon: 'BellOffIcon'},
              {Label: this.i18nT(`Timesheets`),DocumentModel: 'TimeSheet', icon: 'ClockIcon'},
              {Label: this.i18nT(`Survey`),DocumentModel: 'Survey', icon: 'ClipboardIcon'},
              {Label: this.i18nT(`Accounts`),DocumentModel: 'BankAccount', icon: 'BookOpenIcon'},
              {Label: this.i18nT(`Transaction`),DocumentModel: 'Transaction', icon: 'CreditCardIcon'},
              {Label: this.i18nT(`Vendor`),DocumentModel: 'Vendor', icon: 'UsersIcon'},
              {Label: this.i18nT(`Quote`),DocumentModel: 'Quote', icon: 'BriefcaseIcon'},
            //   {Label: this.i18nT(`Project`),DocumentModel: 'Project', icon: 'BoxIcon'},
              {Label: this.i18nT(`Task`),DocumentModel: 'Task', icon: 'CheckCircleIcon'},
            ],
        }
    },
    created() {

        if(!this.displayExtentControls) {
            this.tableColumns = this.tableColumns.filter(colum => {
                if(colum.key === 'assigned_to' || colum.key === 'created_by') {
                    return false
                }
                return true
            })
        }

        this.itemTypes = this.icons.map(i => ({value: i.DocumentModel, text: i.Label}));
        this.itemTypes.unshift({
          value: -1,
          text: i18n(`All types`)
        })

    },
    methods: {
        itemIcon(item) {
            let icon = this.icons.find(i => i.DocumentModel === item.DocumentModel) ? this.icons.find(i => i.DocumentModel === item.DocumentModel).icon : "FilePlusIcon";

            return icon;
        },
        navToDocEdit(item){
            if(this.iCan('documents', 'write')){
                this.$router.push({ name: 'edit-document', params: { id: item.Id } })
            }
        },
        getTypeName(typeId) {
            const item = this.itemTypes.find(type => type.value == typeId)
            return item ? item.text : ''
        },
        getAssigneeName(assignee) {
            const assigneeList = Object.values(assignee)
            return assigneeList.map(ass => ass.Label).join(', ')
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onRestore(element) {

            this.$http
                .post(`trash/restore?ids=${element.item.Id}`)
                .then(() => {
                    let currentTrashCount = this.$store.getters['app/currentTrashCount']
                    this.$store.commit('app/SET_TRASH_COUNT', --currentTrashCount);
                    this.refetchData()
                })
        },
        OnEmptyTrash(){
          this.$swal({
                title: i18n(`Are you sure?`),
                text: i18n(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n(`Yes, delete it!`),
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`trash/empty`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: i18n(`Deleted!`),
                            text: i18n(`The trash has been emptied.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.$store.commit('app/SET_TRASH_COUNT', 0)
                        this.refetchData()
                    })
                }
            })
        },
        onDelete(task) {
            this.$swal({
                title: i18n(`Are you sure?`),
                text: i18n(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`trash/deleteFinal?ids=${task.item.RecordId}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: i18n(`Deleted!`),
                            text: i18n(`The item has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        let currentTrashCount = this.$store.getters['app/currentTrashCount']
                        this.$store.commit('app/SET_TRASH_COUNT', --currentTrashCount)
                        this.refetchData()
                    })
                }
            })
        },
        onBulkRestore() {

            this.selectedItems = this.selectedItems.map(item => item.Id)
            this.$http
                .post(
                    `/trash/restore?ids=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    let currentTrashCount = this.$store.getters['app/currentTrashCount']
                    currentTrashCount = currentTrashCount - this.selectedItems.length
                    this.$store.commit('app/SET_TRASH_COUNT', currentTrashCount)
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: i18n(`Are you sure?`),
                text: i18n(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.selectedItems = this.selectedItems.map(item => item.RecordId)
                    this.$http
                        .delete(`trash/deleteFinal?ids=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: i18n(`Deleted!`),
                                text: i18n(`The items have been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            let currentTrashCount = this.$store.getters['app/currentTrashCount']
                            currentTrashCount = currentTrashCount - this.selectedItems.length
                            this.$store.commit('app/SET_TRASH_COUNT', currentTrashCount)
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const statusOptions = [
            'Downloaded',
            'Draft',
            'Paid',
            'Partial Payment',
            'Past Due'
        ]

        const {
            fetchItems,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refItemsListTable,
            totalItems,
            itemType,
            items,
            refetchData,
        } = useTrashList(router.currentRoute.meta.template === 1)

        return {
            fetchItems,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refItemsListTable,
            totalItems,
            itemType,
            items,
            refetchData,
        }
    },
    computed: {
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
